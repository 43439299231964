<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">Chick</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form method="post" @submit="expenseAdd">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group label="Hatch Id" label-for="Hatch Id ">
                <div class="form-label-group">
                  <b-form-select
                    v-model="selectedHatch"
                    :options="HatchId"
                    text-field="name"
                    value-field="name"
                    v-on:change="getSelectedHatch"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label-for="Price (Per chick)"
                label="Price (Per chick)"
              >
                <div class="form-label-group">
                  <b-form-input
                    v-model="pricePerChick"
                    placeholder="Price (Per chick)"
                    type="text"
                    name="pricePerChick"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="Quantity" label="Quantity">
                <div class="form-label-group">
                  <b-form-input
                    v-model="quantity"
                    placeholder="Quantity"
                    type="text"
                    name="quantity"
                  />
                </div>
              </b-form-group>
              <b-form-group label="Buyer" label-for="Buyer">
                <div class="form-label-group">
                  <b-form-select
                    v-model="selectedBuyer"
                    :options="buyerList"
                    text-field="name"
                    value-field="name"
                    v-on:change="getSelectedUnit"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="Sold No." label="Sold No.">
                <div class="form-label-group">
                  <b-form-input
                    v-model="FormData.soldNo"
                    placeholder="Sold No"
                    type="text"
                    name="soldNo"
                  />
                </div>
              </b-form-group>
               <b-form-group label-for="Total cost" label="Total cost">
                <div class="form-label-group">
                  <b-form-input
                    v-model="FormData.totalCost"
                    placeholder="Total cost"
                    type="text"
                    name="totalCost"
                  />
                </div>
              </b-form-group>
            </b-col>
            
          </b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import * as Vue from "vue";
import axios from "axios";
import { required, email } from "@validations";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
  },

  data() {
    return {
      FormData: {
        rent: null,
        fuel: null,
        electricity: null,
        labour_sal: null,
        medical: null,
        water: null,
        other: null,
      },
      required,
      email,
    };
  },
  methods: {
    expenseAdd(e) {
      e.preventDefault();
      const hatchery_id = this.$route.params.hatcheryId
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          data.append("rent", this.FormData.rent);
          data.append("fuel", this.FormData.fuel);
          data.append("electricity", this.FormData.electricity);
          data.append("labour_sal", this.FormData.labour_sal);
          data.append("medical", this.FormData.medical);
          data.append("water", this.FormData.water);
          data.append("other", this.FormData.other);
          data.append("company_id", this.$route.params.companyId);
          data.append("hatchery_id", this.$route.params.hatcheryId);
          axiosIns
            .post(`web/storeExpense`, data)
            .then((res) => {
              (res);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `data Successfully Added `,
                },
              });
              setTimeout(
                this.$router.push({
                  path: `/apps/expense-entry`,
                }),
                3000
              );
            })
            .catch((error) => {
              (error.response);
              const data = error.response.data.errors;
              (data, "data");
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
